import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { contexts } from "AppProvider";
import { Permission } from "shared/models/Permission";
import { Message } from "semantic-ui-react";

export default observer<{
    some?: Permission[];
    errorMessage?: string;
    every?: Permission[];
    spaceIds?: string[];
    children;
}>(({ some, every, spaceIds, errorMessage, children }) => {
    const session = useContext(contexts).session;

    if (some?.length && !some.some(p => session.hasPermission(p, spaceIds))) {
        return errorMessage ? <Message error content={errorMessage} /> : null;
    }

    if (every?.length && !every.every(p => session.hasPermission(p, spaceIds))) {
        return errorMessage ? <Message error content={errorMessage} /> : null;
    }

    return children;
});
