import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Container, Button, Form, Message, Icon } from "semantic-ui-react";
import JiraConnectStore from "jira-connect-app/JiraConnectStore";
import { Controller, useForm } from "react-hook-form";
import { forEach } from "lodash";
import request from "util/request";
import { toast } from "react-toastify";

interface FormData {
    token: string;
}

export default observer(({ store }: { store: JiraConnectStore }) => {
    const {
        handleSubmit,
        control,
        formState: { isSubmitting }
    } = useForm<FormData>();

    const onSubmit = (data: FormData) => {
        window.AP.context.getToken(jwt => {
            return request()
                .post(`/api/jiraconnectapp/connect?jwt=${jwt}&appToken=${data.token}`)
                .send(data)
                .handle((err, res) => {
                    if (err) {
                        toast.error("Oops, there was an error connecting Jira.");
                        return;
                    }

                    store.setJiraStatus();
                })
                .catch(err => {
                    // don't bubble these errors to sentry
                    if (err.status === 400 || err.status === 401) {
                    } else {
                        throw err;
                    }
                });
        });
    };

    return (
        <Container text style={{ marginTop: "2em" }}>
            <Form onSubmit={handleSubmit(onSubmit)} className="segment">
                <Controller
                    name="token"
                    render={({ value, onChange }) => {
                        return (
                            <Form.Input
                                value={value}
                                onChange={onChange}
                                label="Enter the token from http://app.aberrant.io/jira-connect-app/integration"
                            />
                        );
                    }}
                    defaultValue=""
                    control={control}
                    rules={{ required: "Token is required" }}
                />
                <Button loading={isSubmitting} type="submit">
                    Connect
                </Button>
            </Form>
        </Container>
    );
});
