import { contexts } from "AppProvider";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Icon, Label, List, Popup } from "semantic-ui-react";
import { Notification, NotificationType } from "shared/models/NotificationStore";
import { useAssignees } from "shared/models/workflow/AssigneeStore";
import { WorkflowStatusType } from "shared/models/workflow/Workflow";
import DefaultActivityPlugin, { OptionalItemLink } from "stack/components/activity/DefaultActivityPlugin";
import itemButtonStyles from "stack/links/ItemButtons.module.css";
import DateBadge from "widgets/items/DateBadge";
import StatusBadge from "widgets/items/StatusBadge";
import DeletionIcon from "./utils/DeletionIcon";

const UserNotification = observer(({ item, activity, type, notificationUtc }: Notification) => {
    const users = useAssignees().users;
    const time = moment
        .utc(notificationUtc)
        .local()
        .format("M/D/YYYY h:mma");

    switch (type) {
        case NotificationType.Activity:
            if (!activity) {
                return <>No activity</>;
            }
            const activityElement = DefaultActivityPlugin.activities.get(activity.type);
            if (!activityElement?.modifiedBy) {
                return <>No activity renderer</>;
            }
            const userName = users.get(activity.modifiedBy)?.name || "";

            return (
                <>
                    {userName || "Someone"}{" "}
                    <activityElement.modifiedBy {...activity} item={item} userName={userName} time={time} />
                </>
            );
        case NotificationType.AlmostDue:
            return (
                <>
                    <OptionalItemLink name="an item" {...item} /> is due
                    {item?.endUtc && (
                        <>
                            {" "}
                            on <DateBadge endUtc={item?.endUtc} />
                        </>
                    )}
                </>
            );
        case NotificationType.Overdue:
            return (
                <>
                    <OptionalItemLink name="an item" {...item} /> was due
                    {item?.endUtc && (
                        <>
                            {" "}
                            on <DateBadge endUtc={item?.endUtc} overdue />
                        </>
                    )}
                </>
            );
        case NotificationType.OverdueIssueCreated:
            return (
                <>
                    <OptionalItemLink name="an issue" {...item} /> was created
                    {item?.startUtc && (
                        <>
                            {" "}
                            on <DateBadge endUtc={item?.startUtc} />
                        </>
                    )}
                    for an overdue task.
                </>
            );
        case NotificationType.ReviewedIssueOverdue:
            return (
                <>
                    <OptionalItemLink name="a reviewed issue" {...item} /> was changed from{" "}
                    <StatusBadge status={WorkflowStatusType.IssueRiskAccepted} /> to
                    <StatusBadge status={WorkflowStatusType.IssueOpen} /> since it's passed it's due date without being
                    resolved.
                </>
            );
        default:
            return <></>;
    }
});

const UserNotifications = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { loader, notifications, unreadCount, dismiss, read } = useContext(contexts).notification;
    return (
        <Popup
            position="bottom right"
            basic
            on="click"
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            wide="very"
            trigger={
                <div
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            setIsOpen(!isOpen);
                        }
                    }}
                    tabIndex={0}
                    aria-label="Notifications"
                    style={{ cursor: "pointer", position: "relative" }}
                >
                    <Icon name="bell" title="Notifications" />
                    {unreadCount > 0 && (
                        <Label floating style={{ top: -6 }} circular size="mini" color="red">
                            {unreadCount}
                        </Label>
                    )}
                </div>
            }
        >
            <div
                style={{
                    width: 420,
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(34, 36, 38, 0.15)"
                }}
            >
                <h5>Your Notifications</h5>
                <span
                    style={{ cursor: "pointer", marginLeft: "2em", fontSize: "0.85em", color: "#84808D" }}
                    onClick={read}
                >
                    Mark all as read
                </span>
            </div>
            <List className={itemButtonStyles.container}>
                {loader.succeeded && notifications.length === 0 && (
                    <List.Item>You have no recent notifications.</List.Item>
                )}
                {notifications.map(n => {
                    return (
                        <List.Item key={n.id} className={itemButtonStyles.itemWrapper} style={{ marginBottom: 2 }}>
                            <div style={{ fontWeight: !n.isRead ? 500 : 300, paddingRight: 30 }}>
                                <UserNotification {...n} />
                                <span className={itemButtonStyles.buttons}>
                                    <DeletionIcon
                                        onClick={() => {
                                            dismiss(n.id);
                                        }}
                                    />
                                </span>
                            </div>
                        </List.Item>
                    );
                })}
            </List>
        </Popup>
    );
};

export default observer(UserNotifications);
