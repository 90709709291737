import { IObservableArray, observable, action, ObservableMap, makeObservable } from "mobx";
import request from "util/request";
import { ItemType, DraftStatus, DirectedLink, LinkType, ItemSubType } from "shared/models/Item";
import { WorkflowStatusType, WorkflowType } from "../workflow/Workflow";
import Loader from "util/Loader";
import { AssigneeStatus, ItemAssignee } from "../assignees/ItemAssigneeStore";
import { Activity } from "../activities/Activity";

export enum FilterType {
    ItemIds = 1,
    AssigneeIds = 2,
    Text = 3,
    ItemTypes = 4,
    HasDraft = 5,
    IsEnded = 6,
    Name = 7,
    AuthorizingItemIds = 8,
    DueDateRange = 9,
    WorkflowStatuses = 10,
    ItemSubTypes = 11,
    HasWorkflow = 12,
    IsPublished = 13,
}

export enum ClauseType {
    Must = 1,
    MustNot = 2,
    /** @deprecated */
    Should = 3,
    /** @deprecated */
    Filter = 4
}

export interface ContentSearchItem {
    itemId: string;
    authorizingItemId: string;
    reviewItemId?: string;
    localKey: number;
    name: string;

    type: ItemType;
    subType: ItemSubType;
    draftStatus: DraftStatus;

    displayNumber?: string;
    contextName?: string;
    contextType?: ItemType;
    version: number;

    workflowType?: WorkflowType;

    assigneeId?: string;
    startUtc?: string;
    endUtc?: string;
    completedUtc?: string;
    status: WorkflowStatusType;

    updatedUtc?: string;
}

export interface Entity {
    itemId: string;
    authorizingItemId: string;
    reviewItemId?: string;
    localKey: number;
    name: string;

    type: ItemType;
    subType: ItemSubType;
    draftStatus: DraftStatus;

    displayNumber?: string;
    contextName?: string;
    contextType?: ItemType;
    version: number;

    workflowType?: WorkflowType;

    assigneeId?: string;
    startUtc?: string;
    endUtc?: string;
    completedUtc?: string;
    status: WorkflowStatusType;

    updatedUtc?: string;
}

export interface SearchClientEntity {
    id?: string;
    name: string;
    removed: Boolean;
}

export class Filter {
    public type: FilterType;
    public clause: ClauseType;
    @observable public value: any;

    constructor(type: FilterType, clause: ClauseType, value: any) {
        makeObservable(this);
        this.type = type;
        this.clause = clause;
        this.value = value;
    }
}

export class Sort {
    public field: string;
    public ascending: boolean;

    constructor(field: string, ascending: boolean) {
        this.field = field;
        this.ascending = ascending;
    }
}

export abstract class PaginatedSearchRequest<T> {
    @observable public currentPageNumber: number = 0;
    @observable public totalItems: number = 0;
    @observable public pageSize: number = 25;
    protected _pageCache: ObservableMap<number, T[]> = observable.map(new Map());

    @observable public loader = new Loader(false);
    @observable public results: IObservableArray<T>;

    public submit(): Promise<T[]> {
        return this.setPage(this.currentPageNumber);
    }

    constructor(pageSize?: number) {
        makeObservable(this);
        this.results = observable([]);
        if (pageSize) {
            this.pageSize = pageSize;
        }
    }

    @action
    public setPage(pageNumber: number): Promise<T[]> {
        this.currentPageNumber = pageNumber;
        if (!this._pageCache.has(pageNumber)) {
            return this.loader.load(this.makeRequest(pageNumber));
        } else {
            this.results.replace(this._pageCache.get(pageNumber)!);
            return new Promise(resolve => resolve(this.results));
        }
    }

    // Make request for page and store result in cache
    public abstract makeRequest(pageNumber): Promise<T[]>;
}

export class GenericSearchRequest<T> extends PaginatedSearchRequest<T> {
    @observable public readonly filters: IObservableArray<Filter>;
    @observable public readonly sortOptions: IObservableArray<Sort>;
    public readonly endpoint: string = "/api/search/content";

    constructor(filters: Filter[] = [], sortOptions: Sort[] = [], pageSize?: number) {
        super(pageSize);
        makeObservable(this);
        this.filters = observable(filters.slice());
        this.sortOptions = observable(sortOptions.slice());
    }

    @action
    public toggleFilter(filter: Filter): void {
        const existingFilter = this.filters.find(
            f => f.type === filter.type && f.clause === filter.clause && f.value === filter.value
        );
        if (!existingFilter) {
            this.filters.push(filter);
        } else {
            this.filters.remove(existingFilter);
        }
    }

    @action
    public setSortOptions(sortOptions: Sort[]): GenericSearchRequest<T> {
        this.sortOptions.replace(sortOptions);
        return this;
    }

    @action
    public addSortOption(sort: Sort): GenericSearchRequest<T> {
        const existingSort = this.sortOptions.find(s => s.field === sort.field);
        if (existingSort) {
            this.sortOptions.remove(existingSort);
        }
        this.sortOptions.push(sort);
        return this;
    }

    public makeRequest(pageNumber): Promise<T[]> {
        const filters = this.filters.slice();
        const sortOptions = this.sortOptions.slice();
        return request()
            .post(this.endpoint)
            .send({ sortOptions, filters, pageNumber, pageSize: this.pageSize })
            .handle((err, res) => {
                if (!err) {
                    this.results.replace(res.body.items);
                    this.totalItems = res.body.totalItems;
                } else {
                    console.error(err);
                }
            })
            .then(() => this.results);
    }
}

export default class SearchRequest extends GenericSearchRequest<ContentSearchItem> {}

export class ClientSearchRequest extends GenericSearchRequest<SearchClientEntity> {
    public readonly endpoint: string = "/api/search/clients";
}

export class WatchedItemRequest extends GenericSearchRequest<ContentSearchItem> {
    public readonly endpoint: string = "/api/search/watching";
}

export class RecentActivitySearchRequest extends GenericSearchRequest<Activity> {
    public readonly endpoint: string = "/api/search/activity/recent";
}

export class ReviewsSearchRequest extends GenericSearchRequest<ItemWithApproval> {
    public readonly endpoint: string = "/api/search/reviews";
}

export interface ItemWithApproval {
    approved: boolean;
    item: ContentSearchItem;
}

export interface LinkedContentSearchItem {
    item: ContentSearchItem;
    linkedItems: LinkToItem[];
}

export interface LinkToItem {
    item: ContentSearchItem;
    link: DirectedLink;
}

export abstract class PaginatedLinkedSearchRequest {
    @observable public currentPageNumber: number = 0;
    @observable public totalItems: number = 0;
    @observable public pageSize: number = 25;
    protected _pageCache: ObservableMap<number, LinkedContentSearchItem[]> = observable.map(new Map());

    @observable public loader = new Loader(false);
    @observable public results: IObservableArray<LinkedContentSearchItem>;

    public submit(): Promise<LinkedContentSearchItem[]> {
        return this.setPage(this.currentPageNumber);
    }

    constructor(pageSize?: number) {
        makeObservable(this);
        this.results = observable([]);
        if (pageSize) {
            this.pageSize = pageSize;
        }
    }

    @action
    public setPage(pageNumber: number): Promise<LinkedContentSearchItem[]> {
        this.currentPageNumber = pageNumber;
        if (!this._pageCache.has(pageNumber)) {
            return this.loader.load(this.makeRequest(pageNumber));
        } else {
            this.results.replace(this._pageCache.get(pageNumber)!);
            return new Promise(resolve => resolve(this.results));
        }
    }

    // Make request for page and store result in cache
    public abstract makeRequest(pageNumber): Promise<LinkedContentSearchItem[]>;
}

export class LinkedSearchRequest extends PaginatedLinkedSearchRequest {
    @observable public readonly filters: IObservableArray<Filter>;
    @observable public readonly sortOptions: IObservableArray<Sort>;
    @observable linkType?: LinkType;

    constructor(filters: Filter[] = [], linkType?: LinkType, sortOptions: Sort[] = [], pageSize?: number) {
        super(pageSize);
        makeObservable(this);
        this.filters = observable(filters.slice());
        this.sortOptions = observable(sortOptions.slice());
        this.linkType = linkType;
    }

    @action
    public toggleFilter(filter: Filter): void {
        const existingFilter = this.filters.find(
            f => f.type === filter.type && f.clause === filter.clause && f.value === filter.value
        );
        if (!existingFilter) {
            this.filters.push(filter);
        } else {
            this.filters.remove(existingFilter);
        }
    }

    @action
    public setSortOptions(sortOptions: Sort[]): LinkedSearchRequest {
        this.sortOptions.replace(sortOptions);
        return this;
    }

    @action
    public addSortOption(sort: Sort): LinkedSearchRequest {
        const existingSort = this.sortOptions.find(s => s.field === sort.field);
        if (existingSort) {
            this.sortOptions.remove(existingSort);
        }
        this.sortOptions.push(sort);
        return this;
    }

    public makeRequest(pageNumber): Promise<LinkedContentSearchItem[]> {
        const filters = this.filters.slice();
        const sortOptions = this.sortOptions.slice();
        return request()
            .post(`/api/search/content/linked/${this.linkType}`)
            .send({ sortOptions, filters, pageNumber, pageSize: this.pageSize })
            .handle((err, res) => {
                if (!err) {
                    this.results.replace(res.body.items);
                    this.totalItems = res.body.totalItems;
                } else {
                    console.error(err);
                }
            })
            .then(() => this.results);
    }
}
