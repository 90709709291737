import React from "react";
import { observer } from "mobx-react-lite";
import { Grid, Header } from "semantic-ui-react";
import { Box, Container, Contact, List } from "../Styles";
import { Logo } from "session/Styles";
import InitialRegistrationForm from "../components/InitialRegistrationForm";
import PublicPage from "shared/components/content/PublicPage";

const InitialRegistration = () => {
    const whyAberrantCopyItems = [
        "Secure, web-based application for all your compliance needs",
        "Secure, web-based application for all your compliance needs",
        "Secure, web-based application for all your compliance needs",
        "Secure, web-based application for all your compliance needs"
    ];

    return (
        <PublicPage title="Register" hideHeader hideFooter>
            <Container>
                {/* TODO: Replace this with real logo */}
                <Logo as="h1" textAlign="center">
                    aberrant
                </Logo>

                <Box>
                    <Grid celled="internally">
                        <Grid.Column width={8}>
                            <InitialRegistrationForm />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Header as="h3">Why aberrant?</Header>

                            <List>
                                {whyAberrantCopyItems.map((item, key) => (
                                    <List.Item key={key} icon="checkmark" content={item} />
                                ))}
                            </List>

                            <Contact>
                                Contact us: <a href="mailto:questions@aberrant.io">questions@aberrant.io</a>
                            </Contact>
                        </Grid.Column>
                    </Grid>
                </Box>
            </Container>
        </PublicPage>
    );
};

export default observer(InitialRegistration);
