import { observable, computed, action, makeObservable } from "mobx";
import Loader from "util/Loader";
import { LanguageId } from "util/LocalizationProvider";
import request from "util/request";
import * as Sentry from "@sentry/browser";
import { Permission } from "./Permission";

class User {
    @observable id;
    @observable name;
    @observable email;

    constructor(props) {
        makeObservable(this);
        this.initialize(props);
    }

    @action
    initialize(user) {
        this.id = user.id;
        this.name = user.name;
        this.email = user.email;
    }
}

class Entity {
    @observable id;
    @observable name;
    @observable entityTypeId;

    constructor(props) {
        makeObservable(this);
        this.initialize(props);
    }

    @action
    initialize(entity) {
        this.id = entity.id;
        this.name = entity.name;
        this.entityTypeId = entity.entityTypeId;
    }
}

export default class SessionStore {
    loader = new Loader(false, { immediate: true });

    @observable entityUserId?: string;
    @observable entityId?: string;
    @observable user = new User({});
    @observable isAuthenticated = false;
    @observable useExternalAuth = false;
    @observable requirements = observable.map<string, boolean>({});
    @observable languageId: LanguageId = LanguageId.Default;
    @observable spacePermissions = observable.map<string, Permission[]>({});
    @observable entity = new Entity({});

    constructor() {
        makeObservable(this);
    }

    @computed get isTwoFactorRequired(): boolean {
        return !!this.requirements.get("isTwoFactorRequired");
    }

    @computed get isVerificationRequired(): boolean {
        return !!this.requirements.get("isVerificationRequired");
    }

    @computed get needsTwoFactorSetup(): boolean {
        return !!this.requirements.get("needsTwoFactorSetup");
    }

    @computed get needsEntitySetup(): boolean {
        return !!this.requirements.get("needsEntitySetup");
    }

    @computed get isReady(): boolean {
        return (
            this.isAuthenticated &&
            (!this.isTwoFactorRequired || this.needsTwoFactorSetup) &&
            !this.isVerificationRequired &&
            !this.needsEntitySetup
        );
    }

    logIntoEntity(id) {
        request()
            .post(`/api/session/entity/${id}`)
            .handle(() => {
                window.location.reload();
            });
    }

    load() {
        return this.loader.load(
            request()
                .get(`/api/session`)
                .handle((err, res) => {
                    if (err) return;
                    this.initialize(res.body);
                })
        );
    }

    initialize(session) {
        this.isAuthenticated = session.isAuthenticated;
        this.useExternalAuth = session.useExternalAuth;
        this.languageId = session.language;
        this.spacePermissions.replace(session.spacePermissions || {});
        this.requirements.replace(session.requirements || {});
        this.user.initialize(session.user || {});
        this.entityId = session.entityId;
        this.entityUserId = session.entityUserId;
        this.entity.initialize(session.entity || {});

        if (this.entityUserId) {
            Sentry.setTag("entityuser", this.entityUserId);
        } else {
            Sentry.setTag("entityuser", undefined!);
        }

        if (this.entityId) {
            Sentry.setTag("entity", this.entityId);
        } else {
            Sentry.setTag("entity", undefined!);
        }
    }

    @action
    clear() {
        this.user.initialize({});
        this.isAuthenticated = false;
        this.requirements.clear();
        this.entityUserId = undefined;
        this.entityId = undefined;
    }

    private _hasPermission(permission: Permission, spaceId: string): boolean {
        return !!this.spacePermissions.get(spaceId)?.includes(permission);
    }

    hasPermission(permission: Permission, spaceIds?: string[]): boolean {
        if (!this.entityId) {
            return false;
        }

        if (this._hasPermission(permission, this.entityId)) {
            return true;
        }

        if (!spaceIds) {
            return false;
        }

        return spaceIds.some(id => this._hasPermission(permission, id));
    }
}
