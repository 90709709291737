import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Dropdown, Message, Segment } from "semantic-ui-react";
import request from "util/request";
import { forEach } from "lodash";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

interface FormData {
    method: number;
}

const TwoFactorMethodSelector: React.FC<{
    store?: any;
}> = observer(({ store }) => {
    const {
        handleSubmit,
        control,
        setError,
        errors,
        formState: { isSubmitting }
    } = useForm<FormData>();
    const [formError, setFormError] = useState<string>();
    const [isSmsSelected, setIsSmsSelected] = useState<boolean>();

    const options = store.methodOptions.map(opt => ({ key: opt.value, value: opt.value, text: opt.label }));

    const onSubmit = (data: FormData) => {
        return request()
            .post("/api/session/twofactor/send")
            .send(data)
            .handle((err, res) => {
                if (err) {
                    if (res?.body?.errors) {
                        forEach(res.body.errors, (message, name) => {
                            setError(name as keyof FormData, { message });
                        });
                    } else {
                        setFormError("There was an issue sending your token. Please try again later.");
                    }
                    return;
                }

                setFormError(undefined);
                store.setCodeSent(true);
                toast.success("Your token has been sent");
            })
            .catch(err => {
                if (err.status === 400) {
                } else {
                    throw err;
                }
            });
    };

    return (
        <>
            <Message attached info>
                <Message.Header>Send Two Factor Token</Message.Header>
            </Message>
            <Segment attached="bottom">
                {formError && <Message negative>{formError}</Message>}

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Field>
                        <Controller
                            name="method"
                            render={({ value, onChange }) => {
                                return (
                                    <div>
                                        <Dropdown
                                            placeholder="Choose a method for sending your token"
                                            selection
                                            selectOnBlur={false}
                                            selectOnNavigation={false}
                                            error={!!errors.method}
                                            value={value}
                                            options={options}
                                            onChange={(e, { value }) => {
                                                setIsSmsSelected(value === 2);
                                                onChange(value);
                                            }}
                                        />
                                        {isSmsSelected && 
                                            <Message info compact>
                                                <p>
                                                    By clicking send you are consenting to receive a text message from Aberrant.
                                                </p>
                                                <p>
                                                    Please review our <a href="https://www.aberrant.io/privacy" target="new">Privacy Policy</a>
                                                </p>
                                            </Message>
                                        }
                                    </div>
                                );
                            }}
                            control={control}
                            rules={{ required: "Method is required" }}
                        />
                    </Form.Field>
                    <Button loading={isSubmitting} type="submit">
                        Send
                    </Button>
                </Form>
            </Segment>
        </>
    );
});

export default TwoFactorMethodSelector;
