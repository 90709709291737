import React from "react";
import { Button, Form, Dropdown } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import request from "util/request";
import { forEach } from "lodash";
import { toast } from "react-toastify";
import momentTimezone from "moment-timezone";
import sessionChangeRedirect from "util/sessionChangeRedirect";

interface FormData {
    entity: {
        legalEntityName: string;
        title: string;
        timeZoneId: string;
    };
}

export default ({ entity }: FormData) => {
    const {
        handleSubmit,
        control,
        setError,
        errors,
        formState: { isSubmitting }
    } = useForm<FormData>({ defaultValues: { entity } });

    const onSubmit = (data: FormData) => {
        return request()
            .post("/api/registration/entity")
            .send(data)
            .handle((err, res) => {
                if (err) {
                    if (res?.body?.errors) {
                        forEach(res.body.errors, (message, name) => {
                            setError(name as keyof FormData, { message });
                        });
                    } else {
                        toast.error("There was an error. Please try again later.");
                    }
                    return;
                }

                sessionChangeRedirect();
            })
            .catch(err => {
                // don't bubble these errors to sentry
                if (err.status === 400) {
                } else {
                    throw err;
                }
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="registration">
            <Controller
                name="entity.legalEntityName"
                render={({ value, onChange }) => {
                    return (
                        <Form.Input
                            value={value}
                            onChange={onChange}
                            label="Company Name"
                            error={
                                errors.entity?.legalEntityName && { content: errors.entity?.legalEntityName.message }
                            }
                        />
                    );
                }}
                defaultValue=""
                control={control}
                rules={{ required: "Company name is required" }}
            />
            <Controller
                name="entity.title"
                render={({ value, onChange }) => {
                    return (
                        <Form.Input
                            value={value}
                            onChange={onChange}
                            label="Your Title"
                            error={errors.entity?.title && { content: errors.entity?.title.message }}
                        />
                    );
                }}
                defaultValue=""
                control={control}
                rules={{ required: "Title is required" }}
            />
            <Controller
                name="entity.timeZoneId"
                render={({ value, onChange }) => {
                    return (
                        <Form.Field>
                            <label>Primary Time Zone</label>
                            <Dropdown
                                search
                                selection
                                selectOnBlur={true}
                                error={!!errors.entity?.timeZoneId}
                                value={value}
                                options={momentTimezone.tz.names().map(n => {
                                    return { text: n, value: n };
                                })}
                                onChange={(e, { value }) => {
                                    onChange(value);
                                }}
                            />
                        </Form.Field>
                    );
                }}
                control={control}
                rules={{ required: "Method is required" }}
            />
            <Button primary fluid size="big" type="submit" loading={isSubmitting}>
                Continue
            </Button>
        </Form>
    );
};
