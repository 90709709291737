import React from "react";
import SessionStore from "shared/models/SessionStore";
import AssigneeStore from "shared/models/workflow/AssigneeStore";
import { set as setSession } from "util/getSession";
import LayoutStore from "shared/models/LayoutStore";
import ThirdPartyIntegrationStore from "shared/models/ThirdPartyIntegrationStore";
import SpaceStore from "shared/models/SpaceStore";
import StandardStore from "shared/models/StandardStore";
import NotificationStore from "shared/models/NotificationStore";

const notificationStore = new NotificationStore();
const sessionStore = new SessionStore();
setSession(sessionStore);

sessionStore.load().then(() => {
    if (sessionStore.isReady) {
        notificationStore.load();
    }
});

export const instances = {
    session: sessionStore,
    assignee: new AssigneeStore(),
    layout: new LayoutStore(),
    space: new SpaceStore(),
    standard: new StandardStore(),
    thirdPartyIntegration: new ThirdPartyIntegrationStore(),
    notification: notificationStore
};

window["__contexts"] = instances;

export const contexts = React.createContext(instances);
export const AppProvider = ({ children }) => <contexts.Provider value={instances}>{children}</contexts.Provider>;
