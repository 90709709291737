import React, { useLayoutEffect, useState, useCallback } from "react";
import { Dimmer, Loader, Message } from "semantic-ui-react";
import { observer, useObserver } from "mobx-react-lite";
import PromiseLoader from "util/Loader";

export interface LoadingStore {
    load: () => Promise<any>;
    unload?: () => void;
}

export function useViewStore<T extends LoadingStore>(storeInitializer: () => T): [T | null, PromiseLoader] {
    const [store, setStore] = useState<T | null>(null);
    const [loader] = useState(new PromiseLoader(false, { immediate: true }));
    useLayoutEffect(() => {
        const newStore = storeInitializer();
        loader.load(
            newStore.load().catch(e => {
                console.error(e);
            })
        );
        setStore(newStore);
        window["__store"] = newStore;

        return () => {
            newStore.unload && newStore.unload();
            window["__store"] = undefined;
        };
    }, [storeInitializer, loader]);

    return [store, loader];
}

export const ViewLoader = () => (
    <Dimmer active inverted page>
        <Loader size="massive">Loading</Loader>
    </Dimmer>
);

export const ContentLoader = observer(({ loader, errorMessage }: { loader: PromiseLoader; errorMessage: string }) => {
    return (
        <>
            {loader.error && <Message error content={errorMessage} />}
            {loader.loading && (
                <Dimmer active inverted>
                    <Loader inverted />
                </Dimmer>
            )}
        </>
    );
});

export function useObservingView<T extends LoadingStore>(
    storeInitializer: () => T,
    fn: (store: T) => JSX.Element,
    deps?: React.DependencyList
): JSX.Element {
    const [store, loader] = useViewStore(useCallback(storeInitializer, deps || []));

    return useObserver(() => {
        if (!store || loader.loading) {
            return <ViewLoader />;
        }

        return (
            <>
                {loader.error && (
                    <Dimmer active>
                        <Message error content="Error loading page" />
                    </Dimmer>
                )}
                {fn(store)}
            </>
        );
    });
}
