import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { contexts } from "AppProvider";
import RegistrationRoutes from "./registration/RegistrationRoutes";
// session routes
import Login from "session/views/Login";
import TwoFactor from "session/views/TwoFactor";
import TwoFactorSetup from "session/views/TwoFactorSetup";
import VerifyPhone from "session/views/VerifyPhone";
import VerifyEmail from "session/views/VerifyEmail";
import VerificationRequired from "session/views/VerificationRequired";
import ResetPassword from "session/views/ResetPassword";
import ForgotPassword from "session/views/ForgotPassword";
import FinishRegistration from "registration/views/FinishRegistration";
import JiraConnectRoutes from "jira-connect-app/JiraConnectRoutes";
import ChangeAccount from "session/views/ChangeAccount";

import loadable from "@loadable/component";
import { ViewLoader } from "util/view-hooks";
import { useLocation } from "react-router-dom";
import Privacy from "dashboard/views/Privacy";
import Terms from "dashboard/views/Terms";

const AdminRoutes = loadable(() => import("./admin/AdminRoutes"), {
    fallback: <ViewLoader />
});
const DashboardRoutes = loadable(() => import("./dashboard/DashboardRoutes"), {
    fallback: <ViewLoader />
});
const StackRoutes = loadable(() => import("./stack/StackRoutes"), {
    fallback: <ViewLoader />
});

const RedirectWithReturnUrl = ({ path }) => {
    const location = useLocation();
    const returnUrl =
        new URLSearchParams(location.search).get("return") ?? encodeURIComponent(location.pathname + location.search);
    return <Redirect to={`${path}?return=${returnUrl}`} />;
};

export default observer(() => {
    const session = useContext(contexts).session;
    const loginUrl = session.useExternalAuth ? "/api/session/login" : "/login";
    if (!session.isAuthenticated) {
        return (
            <Switch>
                <Route path="/" exact render={() => {
                    if (session.useExternalAuth) {
                        window.location.replace(loginUrl);
                        return null;
                    }
                    return <Redirect to={loginUrl} />;
                }} />
                <Route path="/jiraConnectApp" component={JiraConnectRoutes} />
                <Route path="/register" component={RegistrationRoutes} />
                <Route path="/login" component={Login} />
                <Route path="/session/forgotpassword" component={ForgotPassword} />
                <Route path="/session/resetpassword" component={ResetPassword} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/terms" component={Terms} />
                <Route render={() => {
                    if (session.useExternalAuth) {
                        window.location.replace(loginUrl);
                        return null;
                    }
                    return <RedirectWithReturnUrl path={loginUrl} />;
                }} />
            </Switch>
        );
    } else if (session.isVerificationRequired) {
        return (
            <Switch>
                <Route path="/session/verify/required" component={VerificationRequired} />
                <Route path="/session/verify/phone" component={VerifyPhone} />
                <Route path="/session/verify/email" component={VerifyEmail} />
                <Route render={() => <RedirectWithReturnUrl path="/session/verify/required" />} />
            </Switch>
        );
    } else if (session.isTwoFactorRequired) {
        const path: string = session.needsTwoFactorSetup ? "/session/twofactor/setup" : "/session/twofactor";
        return (
            <Switch>
                <Route path="/session/twofactor/setup" component={TwoFactorSetup} />
                <Route path="/session/twofactor" component={TwoFactor} />
                <Route render={() => <RedirectWithReturnUrl path={path} />} />
            </Switch>
        );
    } else if (session.needsEntitySetup) {
        const path: string = "/register/entity";
        return (
            <Switch>
                <Route path={path} component={FinishRegistration} />
                <Route render={() => <RedirectWithReturnUrl path={path} />} />
            </Switch>
        );
    }

    return (
        <React.Fragment>
            <Switch>
                {/* Removes trailing slashes (https://github.com/ReactTraining/react-router/issues/4841) */}
                <Route
                    path="/:url*(/+)"
                    exact
                    strict
                    render={({ location }) => <Redirect to={location.pathname.replace(/\/+$/, "")} />}
                />
                {/* Removes duplicate slashes in the middle of the URL */}
                <Route
                    path="/:url(.*//+.*)"
                    exact
                    strict
                    render={({ match }) => <Redirect to={`/${match.params.url.replace(/\/\/+/, "/")}`} />}
                />
                <Route path="/admin" component={AdminRoutes} />
                <Route path="/register" component={RegistrationRoutes} />
                <Route path="/stack" component={StackRoutes} />
                <Route path="/session/verify/email" component={VerifyEmail} />
                <Route path="/session/changeaccount" component={ChangeAccount} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/terms" component={Terms} />
                <Route path="/jiraConnectApp" component={JiraConnectRoutes} />
                <Route path="/" component={DashboardRoutes} />
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </React.Fragment>
    );
});
