import React from "react";
import { Button } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import request from "util/request";
import { forEach } from "lodash";
import { toast } from "react-toastify";
import { Form, Note } from "registration/Styles";
import { Link } from "react-router-dom";
import sessionChangeRedirect from "util/sessionChangeRedirect";

interface FormData {
    token: string;
    registrationUrl: string;
    user: {
        email: string;
        password: string;
        firstName: string;
        lastName: string;
        phone: string;
    };
}

export default ({ user, token, registrationUrl }: FormData) => {
    const {
        handleSubmit,
        control,
        setError,
        errors,
        formState: { isSubmitting }
    } = useForm<FormData>({ defaultValues: { user } });

    const onSubmit = (data: FormData) => {
        return request()
            .post(registrationUrl)
            .send(Object.assign({ token }, data))
            .handle((err, res) => {
                if (err) {
                    if (res?.body?.errors) {
                        forEach(res.body.errors, (message, name) => {
                            setError(name as keyof FormData, { message });
                        });
                    } else {
                        toast.error("There was an error signing up. Please try again later.");
                    }
                    return;
                }

                sessionChangeRedirect();
            })
            .catch(err => {
                // don't bubble these errors to sentry
                if (err.status === 400) {
                } else {
                    throw err;
                }
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="registration">
            <Controller
                name="user.email"
                render={({ value, onChange }) => {
                    return (
                        <Form.Input
                            value={value}
                            onChange={onChange}
                            label="Email"
                            disabled
                            error={errors.user?.email && { content: errors.user.email.message }}
                        />
                    );
                }}
                control={control}
                rules={{
                    required: "Email is required",
                    pattern: { value: /^\S+@\S+$/i, message: "Must be an email address" }
                }}
            />
            <Controller
                name="user.password"
                render={({ value, onChange }) => {
                    return (
                        <Form.Input
                            value={value}
                            onChange={onChange}
                            label="Password"
                            type="password"
                            error={errors.user?.password && { content: errors.user.password.message }}
                        />
                    );
                }}
                control={control}
                defaultValue=""
                rules={{ required: "Password is required" }}
            />
            <Controller
                name="user.firstName"
                render={({ value, onChange }) => {
                    return (
                        <Form.Input
                            value={value}
                            onChange={onChange}
                            label="First Name"
                            error={errors.user?.firstName && { content: errors.user.firstName.message }}
                        />
                    );
                }}
                defaultValue=""
                control={control}
                rules={{ required: "First name is required" }}
            />
            <Controller
                name="user.lastName"
                render={({ value, onChange }) => {
                    return (
                        <Form.Input
                            value={value}
                            onChange={onChange}
                            label="Last Name"
                            error={errors.user?.lastName && { content: errors.user.lastName.message }}
                        />
                    );
                }}
                defaultValue=""
                control={control}
                rules={{ required: "Last name is required" }}
            />
            <Controller
                name="user.phone"
                render={({ value, onChange }) => {
                    return (
                        <Form.Input
                            value={value}
                            onChange={onChange}
                            label="Phone"
                            error={errors.user?.phone && { content: errors.user.phone.message }}
                        />
                    );
                }}
                defaultValue=""
                control={control}
                rules={{
                    required: "Phone number is required",
                    pattern: {
                        value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                        message: "Phone number should be (###) ###-####"
                    }
                }}
            />

            <Button primary fluid size="big" type="submit" loading={isSubmitting}>
                Sign Up
            </Button>
            <Note>
                By signing up, you are agreeing to our <Link to="/privacy">Privacy Policy</Link> and{" "}
                <Link to="/terms">Terms of Use</Link>.
            </Note>
        </Form>
    );
};
