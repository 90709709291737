import { observable, action, makeObservable } from "mobx";
import entityUserLocalStorage from "util/entityUserLocalStorage";

export default class LayoutStore {
    @observable leftNavExpanded: boolean = true;

    constructor() {
        makeObservable(this);
        const props = entityUserLocalStorage.getItem("layout-store");
        if (props) {
            this.leftNavExpanded = props.leftNavExpanded;
        }
    }

    @action
    toggleLeftNavExpanded() {
        this.leftNavExpanded = !this.leftNavExpanded;
        this.save();
    }

    save() {
        entityUserLocalStorage.setItem("layout-store", {
            leftNavExpanded: this.leftNavExpanded
        });
    }
}
