import React, { useState, useCallback, useEffect } from "react";
import { Icon, Search } from "semantic-ui-react";
import { debounce, groupBy, mapValues } from "lodash";
import SearchRequest, { Filter, FilterType, ClauseType, ContentSearchItem } from "shared/models/search/SearchRequest";
import { ItemType } from "shared/models/Item";
import getTypeNameHeader from "util/getTypeNameHeader";
import { useHistory } from "react-router-dom";
import { getUrl } from "stack/links/ItemLink";
import isHotkey from "is-hotkey";

export default () => {
    const [expanded, setExpanded] = useState(false);
    const [searchResults, setSearchResults] = useState<ContentSearchItem[]>();
    const [results, setResults] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [value, setValue] = useState("");
    const history = useHistory();

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (isHotkey("mod+/", e)) {
                e.preventDefault();
                setExpanded(true);
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, []);

    const search = useCallback(
        debounce<any>(v => {
            const search = new SearchRequest();
            search.toggleFilter(new Filter(FilterType.Text, ClauseType.Must, v));
            search.toggleFilter(
                new Filter(FilterType.ItemTypes, ClauseType.Must, [
                    ItemType.Section,
                    ItemType.Document,
                    ItemType.StandardSection,
                    ItemType.Standard,
                    ItemType.Task,
                    ItemType.Action
                ])
            );
            search
                .submit()
                .then(() => {
                    setSearchResults(search.results);
                    setLoading(false);
                    setError(false);
                    setResults(
                        mapValues(
                            groupBy(
                                search.results.slice(0, 10).map(r => ({
                                    id: r.itemId,
                                    "data-local-key": r.localKey,
                                    key: `${r.itemId}-${r.localKey}`,
                                    title: r.displayNumber ? `${r.displayNumber} ${r.name}` : r.name,
                                    description: r.contextName ? r.contextName : undefined,
                                    category: getTypeNameHeader(r)
                                })),
                                "category"
                            ),
                            (results, category) => ({
                                name: category,
                                results
                            })
                        )
                    );
                })
                .catch(() => {
                    setSearchResults([]);
                    setResults([]);
                    setLoading(false);
                    setError(true);
                });
        }, 300),
        []
    );

    const handleSearchChange = useCallback(
        (_, { value }) => {
            setLoading(true);
            setError(false);
            setValue(value);
            search(value);
        },
        [search]
    );

    const handleResultSelect = (_, { result }: { result: any }) => {
        // eslint-disable-next-line eqeqeq
        const searchResult = searchResults!.find(r => r.itemId === result.id && r.localKey == result["data-local-key"]);
        if (!searchResult) {
            throw new Error("Could not find search result in original list");
        }

        const url = getUrl(searchResult);
        if (url) {
            history.push(url);
        } else {
            throw new Error("Could not select search result");
        }
    };

    if (!expanded) {
        return (
            <span>
                <Icon
                    name="search"
                    tabIndex={0}
                    title="Search"
                    style={{ cursor: "pointer " }}
                    onClick={() => setExpanded(true)}
                />
            </span>
        );
    }

    return (
        <Search
            aria-label="Search"
            aligned="right"
            category
            autoFocus
            loading={loading}
            noResultsMessage={error ? "Error loading search results" : "No results found"}
            onResultSelect={handleResultSelect}
            onSearchChange={handleSearchChange}
            results={results}
            value={value}
        />
    );
};
