import { action, computed, makeObservable, observable } from "mobx";
import SearchRequest, { ContentSearchItem, Filter, FilterType, ClauseType } from "./search/SearchRequest";
import { ItemType } from "./Item";
import { contexts } from "AppProvider";
import { useContext, useState, useEffect } from "react";

export const useStandards = () => {
    const standardStore = useContext(contexts).standard;
    const [standards, setStandards] = useState<ContentSearchItem[]>([]);

    const standardsPromise = standardStore.standards;

    useEffect(() => {
        let isSubscribed = true;
        standardsPromise.then(standards => {
            if (isSubscribed) {
                setStandards(standards);
            }
        });
        return () => {
            isSubscribed = false;
        };
    }, [standardsPromise]);

    return { standards };
};

export default class StandardStore {
    @observable private _standards?: Promise<ContentSearchItem[]>;

    constructor() {
        makeObservable<StandardStore, "_standards">(this);
    }

    @computed
    get standards(): Promise<ContentSearchItem[]> {
        if (!this._standards) {
            this.reload();
        }

        return this._standards!;
    }

    @action.bound
    reload() {
        const search = new SearchRequest();
        search.toggleFilter(new Filter(FilterType.ItemTypes, ClauseType.Must, [ItemType.Standard]));
        this._standards = search.submit();
    }
}
