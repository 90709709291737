import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import InitialRegistration from "./views/InitialRegistration";
import AcceptInvitation from "./views/AcceptInvitation";

export default class RegistrationRoutes extends React.Component<any, {}> {
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.url}/invite`} component={AcceptInvitation} />
                <Route exact path={`${match.url}`} component={InitialRegistration} />
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        );
    }
}
