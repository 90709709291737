import "semantic-ui-less/semantic.less";
import React, { useEffect, useContext } from "react";
import { Router } from "react-router-dom";
import GoogleTagManager from "shared/components/analytics/GoogleTagManager";
import Routes from "./Routes";
import { AppProvider, contexts } from "./AppProvider";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/browser";
import { configure } from "mobx";
import { createBrowserHistory } from "history";

// TODO find a more specific home for this
import "react-datepicker/dist/react-datepicker.css";

// TODO rrules?
import "moment/locale/en-gb.js";
import "moment/locale/en-ca.js";
import { IntlProvider } from "react-intl";
import { getLocaleStrings, getLocaleCode, getSelectedLangauge } from "util/LocalizationProvider";

// don't allow state modifications outside actions
configure({ enforceActions: "observed" });

const customHistory = createBrowserHistory();

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    beforeSend(e, hint) {
        const status = (hint?.originalException as any)?.response?.status;
        if (status === 401 || status === 403) {
            return null;
        }

        return e;
    }
});

// TODO: Need this to be a setting, since it changes in envs
const googleTagManagerContainerId = "GTM-P9WC99B";

toast.configure({ autoClose: 5000, hideProgressBar: true });

const AppConsumer = observer(() => {
    useEffect(() => {
        document.title = "Aberrant";
    }, []);

    const session = useContext(contexts).session;

    const selectedLanguage = getSelectedLangauge(session);
    const localeStrings = getLocaleStrings(selectedLanguage);

    return (
        <Router history={customHistory}>
            <React.Fragment>
                <GoogleTagManager containerId={googleTagManagerContainerId} />
                {session.loader.succeeded && (
                    <IntlProvider messages={localeStrings} locale={getLocaleCode(selectedLanguage)} defaultLocale="en">
                        <Routes />
                    </IntlProvider>
                )}
                {session.loader.error && (
                    <div style={{ padding: "1em" }}>
                        Error loading session (
                        <button className="ui link" onClick={() => window.location.reload()}>
                            refresh
                        </button>
                        )
                    </div>
                )}
            </React.Fragment>
        </Router>
    );
});

export default () => {
    return (
        <AppProvider>
            <AppConsumer />
        </AppProvider>
    );
};
