import React from "react";
import styles from "./Footer.module.css";

class Footer extends React.Component {
    render() {
        const { children } = this.props;
        return <div className={styles.footer}>{children}</div>;
    }
}

export default Footer;
