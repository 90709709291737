import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Message, Form, Segment } from "semantic-ui-react";
import request from "util/request";
import { forEach } from "lodash";
import { useForm, Controller } from "react-hook-form";
import sessionChangeRedirect from "util/sessionChangeRedirect";

interface FormData {
    token: string;
}

const TwoFactorForm: React.FC<{
    isSetup?: boolean;
}> = observer(({ isSetup }) => {
    const {
        handleSubmit,
        control,
        setError,
        errors,
        formState: { isSubmitting }
    } = useForm<FormData>();
    const [formError, setFormError] = useState<string>();

    const onSubmit = (data: FormData) => {
        return request()
            .post(isSetup ? "/api/session/twofactor/setup" : "/api/session/twofactor/validate")
            .send(data)
            .handle((err, res) => {
                if (err) {
                    if (res?.body?.errors) {
                        forEach(res.body.errors, (message, name) => {
                            setError(name as keyof FormData, { message });
                        });
                    } else {
                        setFormError("There was an error validating your token. Please try again later.");
                    }
                    return;
                }

                setFormError(undefined);
                sessionChangeRedirect();
            })
            .catch(err => {
                if (err.status === 400) {
                } else {
                    throw err;
                }
            });
    };
    return (
        <>
            <Message attached info>
                <Message.Header>Submit Two Factor Token</Message.Header>
            </Message>
            <Segment attached="bottom">
                {formError && <Message negative>{formError}</Message>}
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="token"
                        render={({ value, onChange }) => {
                            return (
                                <Form.Input
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Enter your token here"
                                    error={errors.token && { content: errors.token.message }}
                                />
                            );
                        }}
                        defaultValue=""
                        control={control}
                        rules={{ required: "Token is required" }}
                    />
                    <Button loading={isSubmitting} type="submit">
                        Submit
                    </Button>
                </Form>
            </Segment>
        </>
    );
});

export default TwoFactorForm;
