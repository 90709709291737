import React from "react";
import { Route, Switch } from "react-router-dom";
import PublicPage from "shared/components/content/PublicPage";
import JiraConnectConfiguration from "./views/JiraConnectConfiguration";
import JiraIntegration from "./views/JiraIntegration";

export default class JiraConnectRoutes extends React.Component<any, {}> {
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.url}/configure`} component={JiraConnectConfiguration} />
                <Route path={`${match.url}/integration`} component={JiraIntegration} />
            </Switch>
        );
    }
}
