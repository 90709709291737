import { contexts } from "AppProvider";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Container, Divider, Form, Header, Icon, List, Message } from "semantic-ui-react";
import PrivatePage from "shared/components/content/PrivatePage";
import { useLoader } from "util/Loader";
import request from "util/request";
import { ViewLoader } from "util/view-hooks";
import SessionStore from "shared/models/SessionStore";

interface Entity {
    id: string;
    name: string;
}

export default observer(() => {
    const loader = useLoader(false, { immediate: true });
    const [entities, setEntities] = useState<Entity[]>([]);

    useEffect(() => {
        loader.load(
            request()
                .get<Entity[]>(`/api/session/entities`)
                .handle((err, res) => {
                    setEntities(res.body);
                })
        );
    }, [loader]);

    const session = useContext(contexts).session;

    if (loader.loading) {
        return <ViewLoader />;
    }

    const currentEntity = entities.find(e => e.id === session.entityId);
    const currentEntities = entities.filter(e => e.id !== session.entityId);
    const sessionStore = new SessionStore();

    return (
        <PrivatePage title="Change Account">
            <div>
                <Container text style={{ padding: "2em 0" }}>
                    <Message attached info>
                        <Message.Header>
                            <Icon name="refresh" /> Change Account
                        </Message.Header>
                    </Message>
                    <Form className="attached segment">
                        Currently logged into <strong>{currentEntity?.name || "<unknown>"}</strong>.
                        {currentEntities.length > 0 && (
                            <>
                                <Divider />
                                <div>
                                    <Header as="h5">Switch To:</Header>
                                    <List>
                                        {currentEntities.map(e => (
                                            <List.Item key={e.id}>
                                                <button onClick={() => sessionStore.logIntoEntity(e.id)} className="ui link">
                                                    {e.name}
                                                </button>
                                            </List.Item>
                                        ))}
                                    </List>
                                </div>
                            </>
                        )}
                    </Form>
                </Container>
            </div>
        </PrivatePage>
    );
});
