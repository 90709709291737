import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Grid, Header, Message } from "semantic-ui-react";
import Page from "shared/components/content/Page";
import { useLoader } from "util/Loader";
import request from "util/request";
import { ViewLoader } from "util/view-hooks";
import FinishRegistrationForm from "../components/FinishRegistrationForm";
import { Box, Container, Contact } from "../Styles";
import { Logo } from "session/Styles";

const FinishRegistration = () => {
    const loader = useLoader(false, { immediate: true });
    const [formData, setFormData] = useState<any>();

    useEffect(() => {
        loader.load(
            request()
                .get(`/api/registration/entity/form`)
                .handle((err, res) => {
                    if (err) return;
                    setFormData(res.body);
                })
        );
    }, [loader]);

    if (loader.loading) {
        return <ViewLoader />;
    }

    if (loader.error) {
        return (
            <Container>
                <Message error>there was an error loading your account. Please try again later.</Message>
            </Container>
        );
    }

    return (
        <Page title="Finish Registration">
            <Container>
                {/* TODO: Replace this with real logo */}
                <Logo as="h1" textAlign="center">
                    aberrant
                </Logo>

                <Box>
                    <Grid celled="internally">
                        <Grid.Column width={8}>
                            <FinishRegistrationForm {...formData} />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Header align="center" as="h3">
                                Almost Done!
                            </Header>
                            <Contact>
                                Contact us: <a href="mailto:questions@aberrant.io">questions@aberrant.io</a>
                            </Contact>
                        </Grid.Column>
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
};

export default observer(FinishRegistration);
